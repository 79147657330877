import React from 'react';
import moment from 'moment';
import './countdown.css'

export default class Countdown extends React.Component {
    state = {
        mounth: undefined,
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            const now = moment();
            const countdown = moment(then - now);
            const days = then.diff(now, 'days');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;



        if (!seconds) {
            return null;
        }

        return (
            <div>
                <div className="countdown-wrapper">

                    {days && (
                        <div className="countdown-item">
                            {days}
                            <span>{this.props.daysText}</span>
                        </div>
                    )}
                    {hours && (
                        <div className="countdown-item">

                            {hours}
                            <span>{this.props.hoursText}</span>
                        </div>
                    )}
                    {minutes && (
                        <div className="countdown-item">

                            {minutes}
                            <span>{this.props.minutesText}</span>
                        </div>
                    )}
                    {seconds && (
                        <div className="countdown-item">

                            {seconds}
                            <span>{this.props.secondsText}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
