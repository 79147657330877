import React from "react";
import "./App.css";
import {
  Button,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Embed,
  Flag,
} from "semantic-ui-react";
import Countdown from "./components/Countdown/countdown";
import {
  setTranslations,
  setDefaultLanguage,
  useTranslation,
  setLanguage,
  getLanguage,
} from "react-multi-lang";
import fr from "./translations/fr.json";
import en from "./translations/en.json";

setDefaultLanguage("fr");
setTranslations({ en, fr });

function App() {
  const t = useTranslation();

  return (
    <div className="App">
      <Grid>
        <Button
          basic
          size="mini"
          id="langButton"
          inverted
          onClick={() => {
            if (getLanguage() === "fr") setLanguage("en");
            else setLanguage("fr");
          }}
        >
          {getLanguage() === "fr" ? <Flag name="uk" /> : <Flag name="fr" />}
        </Button>
      </Grid>
      <Embed
        active={true}
        color="black"
        autoplay={true}
        source="youtube"
        iframe={{
          allowFullScreen: true,
          allow: "autoplay",
          style: {
            margin: 0,
          },
          src:
            "https://www.youtube.com/embed/fnxz1pgJfUI?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=fnxz1pgJfUI",
        }}
        placeholder="/images/01_Couleurs_Noir.jpg"
      />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Segment>
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Row>
            <Header inverted className="jex text-pink" as="h3">
              {t("title.december")}
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header inverted as="h1">
              {t("title.exp")}
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Countdown
              timeTillDate="05 12 2020, 8:00 am"
              timeFormat="DD MM YYYY, h:mm a"
              daysText={t("date.days")}
              hoursText={t("date.hours")}
              minutesText={t("date.minutes")}
              secondsText={t("date.seconds")}
            />
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Image src="/images/00_Couleurs_Blanc.png" size="huge" centered />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Grid
          textAlign="center"
          verticalAlign="middle"
          columns={2}
          className="jex text-size"
        >
          <Grid.Column mobile={16} tablet={12} computer={8}>
            {t("text.body")}
            <br />
            <h2>{t("text.title")}</h2>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Grid stackable columns={3} textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <Image size="medium" centered src="/images/WeAreJE.png" />
          </Grid.Column>
          <Grid.Column>
            <Image size="medium" centered src="/images/JEX.png" />
          </Grid.Column>
          <Grid.Column>
            <Image size="medium" centered src="/images/ReEvent.png" />
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider />
        <Header inverted className="jex text-grey" as="h3">
          {t("wallpaper")}
        </Header>
        <Grid stackable columns={4} textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <a href="/images/01_Couleurs_Noir.jpg" download>
              <Image
                bordered
                size="medium"
                centered
                src="/images/01_Couleurs_Noir.jpg"
                label={{ corner: "left", icon: "arrow down", color: "grey" }}
              />
            </a>
          </Grid.Column>
          <Grid.Column>
            <a href="/images/01_Couleurs_Blanc.jpg" download>
              <Image
                bordered
                size="medium"
                centered
                src="/images/01_Couleurs_Blanc.jpg"
                label={{ corner: "left", icon: "arrow down", color: "grey" }}
              />
            </a>
          </Grid.Column>
          <Grid.Column>
            <a href="/images/00_Couleurs_Blanc.jpg" download>
              <Image
                bordered
                size="medium"
                centered
                src="/images/00_Couleurs_Blanc.jpg"
                label={{ corner: "left", icon: "arrow down", color: "grey" }}
              />
            </a>
          </Grid.Column>
          <Grid.Column>
            <a href="/images/00_Couleurs_Noir.jpg" download>
              <Image
                bordered
                size="medium"
                centered
                src="/images/00_Couleurs_Noir.jpg"
                label={{ corner: "left", icon: "arrow down", color: "grey" }}
              />
            </a>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider />
        <Divider hidden /> <Divider hidden />
        <Header inverted className="jex text-grey" as="h3">
          {t("playlist")}
        </Header>
        <iframe
        title="playlist"
          src="https://open.spotify.com/embed/playlist/3j65YuzORe0q7KMfSsqMLO"
          width="300"
          height="380"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
        
        <Divider hidden />
        <Divider hidden />
        <Divider />
        <Divider hidden />
        <Divider hidden />
        <Image src="/images/01_Couleurs.png" size="medium" centered />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Header inverted className="jex text-grey" as="h3">
          {t("footer")}
        </Header>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Button
          basic
          size="massive"
          color="grey"
          style={{ boxShadow: "0px !important" }}
          icon="facebook"
          id="noborder"
          target="_blank"
          href="https://fr-fr.facebook.com/junior.entreprises/"
        />
        <Divider vertical hidden />
        <Button
          basic
          size="massive"
          color="grey"
          icon="twitter"
          id="noborder"
          target="_blank"
          href="https://twitter.com/cnje"
        />
        <Divider vertical hidden />
        <Button
          basic
          size="massive"
          color="grey"
          icon="instagram"
          id="noborder"
          target="_blank"
          href="https://www.instagram.com/cnje/?hl=fr"
        />
        <Divider vertical hidden />
        <Button
          basic
          size="massive"
          color="grey"
          icon="linkedin"
          id="noborder"
          target="_blank"
          href="https://fr.linkedin.com/company/conf-d-ration-nationale-des-junior-entreprises"
        />
        <Divider vertical hidden />
        <Button
          basic
          size="massive"
          color="grey"
          icon="youtube"
          id="noborder"
          target="_blank"
          href="https://www.youtube.com/channel/UClvmCDc9kdgFJTRMSQzHXow"
        />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Segment>
    </div>
  );
}

export default App;
